<template>
  <!-- Cart -->
  <TransitionRoot as="div" :show="showCart" :isEmpty="isEmpty">
    <Dialog as="div" class="cart-slide" :initialFocus="xBtn">
      <div>
        <TransitionChild
          as="div"
          enter="ease-in-out duration-50"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-50"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="cart-overlay" @click="$emit('close')" />
        </TransitionChild>

        <div class="cart-contents">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="cart-container">
              <slot name="entry" />
              <div class="cart-card">
                <!-- Cart Header -->
                <div class="cart-header">
                  <div class="inner">
                    <DialogTitle>{{ heading }}</DialogTitle>
                    <div class="flex items-center space-x-5">
                      <button @click="$emit('close')" ref="xBtn">
                        <XIcon class="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                  <slot name="header" />
                </div>
                <!-- Cart Content -->
                <div class="cart-items" :class="{ 'justify-center': isEmpty }">
                  <slot v-if="!isEmpty || cart_loading" />
                  <div class="cart-empty" v-else>
                    <img src="@/assets/images/emptybox.svg" alt="Empty Cart" />
                    <p>
                      {{
                        emptyTitle ||
                          "You haven't added anything to your order."
                      }}
                    </p>
                    <span v-if="!emptyDesc"
                      ><a href="/" @click.prevent="$emit('navigate')"
                        >Click here</a
                      >
                      for suggestions on what products our data tells us would
                      do well at your store.</span
                    >
                    <span v-else>{{ emptyDesc }}</span>
                  </div>
                </div>
                <!-- Cart Footer -->
                <div class="cart-footer" v-if="!isEmpty">
                  <slot name="footer" />
                </div>
              </div>
              <ConfirmRelative
                :isVisible="showClearModal"
                :isRelative="true"
                cancelButton="CANCEL"
                confirmButton="CLEAR ORDER"
                @cancel="showClearModal = false"
                @confirm="$emit('clear'), (showClearModal = false)"
              >
                <div class="space-y-2">
                  <p class="text-sm font-light text-theme-body">
                    Are you sure you want to clear your cart?
                  </p>
                  <p class="text-sm font-normal text-theme-body">
                    This will remove all items from your order.
                  </p>
                </div>
              </ConfirmRelative>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import { ref } from "vue";
import ConfirmRelative from "./ConfirmRelative.vue";
export default {
  name: "Cart",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    ConfirmRelative,
  },
  props: {
    heading: {
      type: String,
      default: "Your order",
    },
    subheading: {
      type: String,
      default: "",
    },
    closeBtn: {
      type: String,
      default: "Clear Cart",
    },
    toggleBtn: {
      type: String,
      default: "Expand All",
    },
    showCart: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    emptyTitle: {
      type: String,
      default: "",
    },
    emptyDesc: {
      type: String,
      default: "",
    },
  },
  computed: {
    cart_loading() {
      return this.$store.getters.loading;
    },
  },
  setup(props, { emit }) {
    const showClearModal = ref(false);

    const xBtn = ref(null);

    const clearCart = () => {
      showClearModal.value = true;
    };

    return {
      showClearModal,
      clearCart,
      xBtn,
    };
  },
};
</script>
<style lang="scss" scoped>
.cart-slide {
  @apply fixed inset-0 overflow-hidden z-50 font-default;
  > div {
    @apply absolute inset-0 overflow-hidden;
  }
  .cart-overlay {
    @apply fixed inset-0 bg-theme-secondary bg-opacity-20 transition-opacity;
    z-index: 999;
  }
  .cart-contents {
    @apply flex fixed top-0 bottom-0 right-0 max-w-full focus:outline-none;
    z-index: 9999999999;
  }
  .cart-container {
    @apply w-screen md:max-w-2xl;
  }
  .cart-card {
    @apply h-full flex flex-col justify-between bg-white shadow-xl overflow-hidden;
    .cart-header {
      @apply flex flex-col items-stretch px-6 py-6 bg-gray-100 bg-opacity-70 gap-3;

      .inner {
        @apply flex items-center justify-between;
      }

      h2 {
        @apply text-lg font-semibold text-brand-primary;
      }
      button {
        @apply bg-transparent text-xs font-medium uppercase text-brand-body tracking-wide hover:text-theme-dark;
      }
    }
    .cart-items {
      @apply flex flex-col space-y-5 flex-grow overflow-y-scroll px-6 md:pr-2 py-4;
      @include CssScrollbar();
    }
    .cart-empty {
      @apply flex flex-col items-center space-y-2 px-4;
      p {
        @apply text-base text-brand-secondary font-semibold text-center;
      }
      span {
        @apply text-sm text-brand-body w-72 text-center;
        a {
          @apply border-b border-dashed border-brand-body;
        }
      }
    }
    .cart-footer {
      @apply px-4 pb-6 sm:pb-6 lg:pb-8;
      button {
        @apply w-full rounded-lg bg-brand-ash text-brand-body bg-opacity-50 font-semibold py-2.5 cursor-default;
      }
    }
  }
}
</style>
