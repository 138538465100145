<template>
  <TransitionRoot as="template" :show="isVisible">
    <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leave-from="opacity-100 translate-y-0 sm:scale-100"
      leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <div
        class="absolute inset-0 bg-theme-secondary bg-opacity-20 p-5 flex items-center justify-center z-50"
      >
        <div
          class="relative flex flex-col bg-white z-50 w-96 rounded-lg overflow-hidden"
        >
          <div
            class="flex items-center border-b border-gray-100 px-5 py-2 space-x-1.5"
          >
            <Icon class="text-theme-dark opacity-70 w-5 h-5" :name="icon" />
            <h3 class="text-base font-semibold text-theme-dark opacity-70">
              {{ title }}
            </h3>
          </div>
          <div
            class="w-full p-5 text-left space-y-8 text-theme-body text-md font-normal leading-normal"
          >
            <div class="space-y-5">
              <slot />
            </div>
            <div class="grid grid-cols-2 gap-5">
              <div @click="$emit('cancel')">
                <Button :title="cancelButton" class="no-bg xsmall uppercase" />
              </div>
              <div @click="$emit('confirm')">
                <Button
                  :title="confirmButton"
                  class="xsmall uppercase red-bg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </TransitionChild>
  </TransitionRoot>
</template>

<script>
import { TransitionChild, TransitionRoot } from "@headlessui/vue";

export default {
  name: "ConfirmRelative",
  components: {
    TransitionChild,
    TransitionRoot,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "info",
    },
    title: {
      type: String,
      default: "Notice",
    },
    cancelButton: {
      type: [String, Boolean],
      default: "Go Back",
    },
    confirmButton: {
      type: [String, Boolean],
      default: "Yes",
    },
  },
};
</script>
